const GeneralLayout = () => import('@/layouts/General.vue');

const Module = () => import('./Module.vue');
const SellerList = () => import('./views/SellerList.vue');
const RequestList = () => import('./views/RequestList.vue');
const SellerDetail = () => import('./views/SellerDetail.vue');

const moduleRoute = {
  path: '',
  component: GeneralLayout,
  children: [
    {
      path: '/seller',
      component: Module,
      children: [
        {
          path: '/',
          name: 'seller',
          component: SellerList,
          meta: {
            public: false,
            onlyLoggedOut: false,
            title: 'Manage Seller',
          },
        },
      ],
    },
    {
      path: '/seller/request',
      component: Module,
      children: [
        {
          path: '/',
          name: 'seller-request',
          component: RequestList,
          meta: {
            public: false,
            onlyLoggedOut: false,
            title: 'Verify Seller',
          },
        },
      ],
    },
    {
      path: '/seller/:id',
      component: Module,
      children: [
        {
          path: '/',
          name: 'seller-detail',
          component: SellerDetail,
          meta: {
            public: false,
            onlyLoggedOut: false,
            title: 'Detail Seller',
          },
        },
      ],
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
