const GeneralLayout = () => import('@/layouts/General.vue');

const Module = () => import('./Module.vue');
const CustomerList = () => import('./views/CustomerList.vue');

const moduleRoute = {
  path: '',
  component: GeneralLayout,
  children: [
    {
      path: '/customer',
      component: Module,
      children: [
        {
          path: '/',
          name: 'customer',
          component: CustomerList,
          meta: {
            public: false,
            onlyLoggedOut: false,
            title: 'Manage Customer',
          },
        },
      ],
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
