const GeneralLayout = () => import('@/layouts/General.vue');

const Module = () => import('./Module.vue');
const AdsList = () => import('./views/AdsList.vue');

const moduleRoute = {
  path: '',
  component: GeneralLayout,
  children: [
    {
      path: '/ads',
      component: Module,
      children: [
        {
          path: '/',
          name: 'ads',
          component: AdsList,
          meta: {
            public: false,
            onlyLoggedOut: false,
            title: 'Manage Ads',
          },
        },
      ],
    },
  ],
};

export default (router) => {
  router.addRoutes([moduleRoute]);
};
