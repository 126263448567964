import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import '@/assets/css/main.scss';

import { registerModules } from './register-modules';

import adsModule from './modules/ads';
import loginModule from './modules/login';
import sellerModule from './modules/seller';
import packageModule from './modules/package';
import customerModule from './modules/customer';
import dashboardModule from './modules/dashboard';
import packageHighlightModule from './modules/package-highlight';

/* This is a way to register the modules that are used in the application. */
registerModules({
  ads: adsModule,
  login: loginModule,
  seller: sellerModule,
  package: packageModule,
  customer: customerModule,
  dashboard: dashboardModule,
  packageHighlight: packageHighlightModule,
});

import Toast from './plugins/VueToast';
import vuetify from './plugins/vuetify';
import Convert from './plugins/convert';
import VeeValidate from './plugins/veeValidate';
import VueApexCharts from './plugins/VueApexCharts';
import VCurrencyField from './plugins/VCurrencyField';

/* This is a way to print out the version number of the application. */
import { log } from './utils/console';
const package_json = require('../package.json');

log(`Version v${package_json.version}`, 'info');

const ENVIRONMENT = process.env.VUE_APP_STAGE || 'local';
const CONFIG_ENVIRONMENT = require(`@/config/${ENVIRONMENT.toLowerCase()}.json`);
const { environment } = CONFIG_ENVIRONMENT.env;
if (environment !== 'production') {
  log(`Environment: ${environment}`, 'warning');
}

new Vue({
  router,
  store,
  Toast,
  Convert,
  vuetify,
  VeeValidate,
  VueApexCharts,
  VCurrencyField,
  render: (h) => h(App),
}).$mount('#app');
