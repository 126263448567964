import Vue from 'vue';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; //Globally import VTextField

Vue.component('VTextField', VTextField);
Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  decimalLength: 0,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

export default VCurrencyField;
